import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Accordion } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <Seo title="Naturspielgruppe in Uster" />
    <StaticImage
      src="../images/index.jpg"
      quality={50}
      formats={["auto"]}
      alt="Spaziergang im Wald"
    />
    <div className="content">
    <div  style={{backgroundColor: 'rgba(186, 225, 255, 0.8)', paddingTop:'10px', borderRadius:'10px'}}>
        <h1>Wir machen eine Pause</h1>
        <p>Wir legen eine Pause ein vom August 2024 bis August 2025.</p>
    </div>
    <h1>Willkommen bei der Spielgruppe Wakiti in Uster</h1>
    <h2>Wald, Kinder & Tiere, alles was uns am Herzen liegt</h2>
    <p>Besonders die Jüngsten unter uns blühen in der Natur sichtbar auf. Sie entdecken die Umwelt mit all ihren Sinnen und können ihrer Neugierde folgen. Die Kinder haben die Möglichkeit selbstbestimmt zu lernen und machen existenzielle Erfahrungen. Die Spielgruppe bietet Platz, wo Kinder noch Kinder sein dürfen.</p>
    <p>
      <Link className="btn btn-primary btn-cust" to="/anmeldungundkontakt/">Hier zur Anmeldung</Link> <br />
    </p>
    <div style={{marginTop: 70}}>
      <h3 className="faqTitle">Häufig gestellte Fragen</h3>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="AccordionHeader">Seid ihr eine Waldspielgruppe?</Accordion.Header>
          <Accordion.Body>
          Sofern es das Wetter erlaubt, verbringen wir die gesamte Zeit im Freien. Den anliegenden Wald erreichen wir in wenigen Gehminuten. Je nach Interessen der Kinder bleiben wir auf dem Hof, besuchen weitere Höfe oder gehen in den Wald. Die Kinder werden in die Morgengestaltung miteinbezogen, somit gestaltet sich das Programm flexibel. 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="AccordionHeader">Wo in Uster finde ich die Spielgruppe Wakiti?</Accordion.Header>
          <Accordion.Body>
          Der Treffpunkt der Spielgruppe ist auf dem Hof Neufuhr in Uster, nur fünf Minuten mit dem Auto vom Bahnhof Uster entfernt. Wenn Sie von Oberuster/Nossikon Richtung Sulzbach fahren, befindet sich die Spielgruppe auf der linken Seite unterhalb des Waldes.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="AccordionHeader">Ab welchem Alter ist der Eintritt in die Spielgruppe möglich?</Accordion.Header>
          <Accordion.Body>
          Grundsätzlich darf jedes Kind ab drei Jahren die Spielgruppe besuchen. Je nach Entwicklungsstand des Kindes kann das Alter variieren.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="AccordionHeader">Gibt es Schnuppertage bei euch?</Accordion.Header>
          <Accordion.Body>
          Ja wir bieten Schnuppertage bei uns an. Die Spielgruppe bereits vor dem ersten, offiziellen Tag mit euren Kindern zu besuchen ist empfehlenswert. Für Terminvereinbarungen können Sie die Spielgruppenleitung gerne kontaktieren.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
    </div>
  </Layout>
)

export default IndexPage
